import $ from 'jquery';
import sayThankYou from './form/sayThankYou';

const emailValidationRegexp = /^[\w\.-]+@[\w\.]+\.\w+$/i;

const setAlert = ($element, message = '') => {
  $element.siblings('.alert').text(message);
};

const isRequiredValid = $form => {
  let isValidGlobal = true;
  const $elements = $form.find('input[required]');

  $elements.each(function() {
    const $this = $(this);
    const isValid = !!$this.val();
    if (!isValid) {
      isValidGlobal = false;
    }

    $this.toggleClass('invalid', !isValid);
    setAlert($this, isValid ? '' : 'Data is required');
  });

  return isValidGlobal;
};

const isValidEmail = $form => {
  const $emailElement = $form.find('input[name=email]');
  const isValid = emailValidationRegexp.test($emailElement.val().trim());

  $emailElement.toggleClass('invalid', !isValid);
  setAlert($emailElement, isValid ? '' : 'Please, enter a valid email');

  return isValid;
};

const generateFormData = $form => {
  const $nameElement = $form.find('input[name=name]');
  const $emailElement = $form.find('input[name=email]');
  const $messageElement = $form.find('textarea[name=message]');

  const formData = new FormData();
  formData.append( 'name', $nameElement.val() );
  formData.append( 'email', $emailElement.val() );
  formData.append( 'message', $messageElement.val() );

  return formData;
};

const renderThankYou = form => {
  sayThankYou.call(form);
  form[0].reset();
};

const $form = $('#form');

$form.on('submit', e => {
  e.preventDefault();

  if (!isRequiredValid($form)) return false;
  if (!isValidEmail($form)) return false;

  $.ajax({
    // TODO: change url
    url: 'http://localhost:1234/',
    data: generateFormData($form),
    processData: false,
    contentType: false,
    type: 'POST',
    success: () => {
      renderThankYou($form);
    },
    error: ({ status, statusText }) => {
      console.log(status, statusText);
      // TODO: it's for testing. remove on production.
      renderThankYou($form);
    },
  });
});
