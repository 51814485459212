import $ from 'jquery';

const $navigations = $('.navigation');
const $mainSectionContent = $('.main-section__content');
const $hamburger = $('#hamburger');
const $headerNavigation = $('#header-navigation');
const $body = $('body');

const toggleMobileNavigation = () => {
  $hamburger.toggleClass('_open');
  $headerNavigation.toggleClass('_open');
  $body.toggleClass('_overflow-hidden');
};

const onMenuClick = function (e) {
  e.preventDefault();

  if ($headerNavigation.hasClass('_open')) {
    toggleMobileNavigation();
  }

  const id = $(this).attr('href');
  const scrollTop = $(id).offset().top;
  $('html, body').animate({ scrollTop }, 500);
};

$navigations.on('click touchstart', 'a', onMenuClick);
$mainSectionContent.on('click touchstart', 'a', onMenuClick);

$hamburger.on('click', toggleMobileNavigation);
