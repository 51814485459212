import $ from 'jquery';

const sayThankYou = function () {
  $(this).append(
    '<div class="thank-you">' +
    '<svg><use xlink:href="#icon-submit-success"></use></svg>' +
    '<div class="thank-you__title">Thank you for the request!</div>' +
    '<div class="thank-you__text">One of our team members will be in contact with you shortly.</div>' +
    '</div>'
  );
  return true;
};

export default sayThankYou;
